<template>
  <div>
    <base-modal
      :showing="visible && !deleteVisible && !detailMovement.visible"
      :size="size"
      @close="onClose"
      @opened="onOpened"
    >
      <base-card
        :title="`Detail ${label}`"
        :description="`Detail Faktur ${label}`"
        :with-style="false"
      >
        <template #action>
          <base-badge
            :color="
              order.data.attributes.is_valid_for_payment ? 'green' : 'yellow'
            "
            >{{ order.data.attributes.order_status }}</base-badge
          >
        </template>

        <div class="space-y-6 border-t border-gray-200 py-5">
          <dl class="grid grid-cols-3 gap-6">
            <slot
              name="attributes"
              :classes="{
                dt: 'text-xs text-gray-700',
                dd: 'mt-1 text-sm font-bold text-gray-900',
              }"
              :order="order"
              :destination-office="destinationOffice"
            >
              <div v-if="columns.includes('code')" class="w-full">
                <dt class="text-xs text-gray-700">No {{ label }}</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{ order.data.attributes.origin_code }} /
                  {{ order.data.attributes.destination_code }}
                </dd>
              </div>
              <div v-if="columns.includes('warehouse')" class="w-full">
                <dt class="text-xs text-gray-700">Kode Gudang</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{ order.data.attributes.origin_office_code }} /
                  {{ order.data.attributes.origin_warehouse_code }}
                </dd>
              </div>
              <div v-if="columns.includes('office')" class="w-full">
                <dt class="text-xs text-gray-700">Kode / Nama Kantor</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{
                    destinationOffice ? destinationOffice.attributes.code : '-'
                  }}
                  /
                  {{
                    destinationOffice ? destinationOffice.attributes.name : '-'
                  }}
                </dd>
              </div>
              <div v-if="columns.includes('date')" class="w-full">
                <dt class="text-xs text-gray-700">Dibuat Tanggal</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  <order-date-label :order="order.data" />
                </dd>
              </div>
              <div v-if="columns.includes('user')" class="w-full">
                <dt class="text-xs text-gray-700">Dibuat Oleh</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{
                    order.data.attributes.created_by
                      ? order.data.attributes.created_by.office.name
                      : '-'
                  }}
                  /
                  {{
                    order.data.attributes.created_by
                      ? order.data.attributes.created_by.user.name
                      : '-'
                  }}
                </dd>
              </div>
              <div v-if="columns.includes('area')" class="w-full">
                <dt class="text-xs text-gray-700">Wilayah Area</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{ order.data.attributes.area_name }}
                  ({{ order.data.attributes.area_code }})
                </dd>
              </div>
              <div v-if="columns.includes('shipment_type')" class="w-full">
                <dt class="text-xs text-gray-700">Diambil / Dikirim</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{
                    order.data.attributes.order_shipment === 'pickup'
                      ? 'Diambil'
                      : 'Dikirim'
                  }}
                </dd>
              </div>
              <div v-if="columns.includes('stock_type')" class="w-full">
                <dt class="text-xs text-gray-700">Metode {{ label }}</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{
                    order.data.attributes.is_using_stock
                      ? 'Dengan Barang'
                      : 'Tanpa Barang'
                  }}
                </dd>
              </div>
              <div v-if="columns.includes('loan')" class="w-full">
                <template v-if="order.data.attributes.is_using_stock">
                  <dt class="text-xs text-gray-700">Metode Pembayaran</dt>
                  <dd class="mt-1 text-sm font-bold text-gray-900">
                    {{
                      order.data.attributes.is_loan ? 'Pinjam Barang' : 'Saldo'
                    }}
                  </dd>
                </template>
              </div>
              <div
                v-if="
                  columns.includes('address') &&
                  order.data.attributes.order_shipment === 'delivery'
                "
                class="w-full"
              >
                <dt class="text-xs text-gray-700">Dikirim ke Alamat</dt>
                <dd class="mt-1 text-sm font-bold text-gray-900">
                  {{ order.data.attributes.shipment_address || '-' }}
                </dd>
              </div>
            </slot>
            <div v-if="withItems" class="col-span-full">
              <dt class="text-xs text-gray-700">Barang</dt>
              <dd class="mt-1">
                <datatable
                  :scroll-x="false"
                  :columns="
                    [
                      { id: 'code', name: 'Kode Barang' },
                      { id: 'qty', name: 'Jumlah', theadClass: 'text-right' },
                      orderDetailColumns.includes('packed_qty')
                        ? {
                            id: 'packed_qty',
                            name: 'Dikirim',
                            theadClass: 'text-right',
                          }
                        : null,
                      orderDetailColumns.includes('unpacked_qty')
                        ? {
                            id: 'unpacked_qty',
                            name: 'Belum Dikirim',
                            theadClass: 'text-right',
                          }
                        : null,
                      orderDetailColumns.includes('price')
                        ? {
                            id: 'price',
                            name: 'Harga Barang',
                            theadClass: 'text-right',
                          }
                        : null,
                      orderDetailColumns.includes('weight')
                        ? {
                            id: 'price',
                            name: 'Berat',
                            theadClass: 'text-right',
                          }
                        : null,
                      {
                        id: 'total_price',
                        name: 'Total Harga',
                        theadClass: 'text-right',
                      },
                      orderDetailColumns.includes('pv')
                        ? {
                            id: 'pv',
                            name: 'PV',
                            theadClass: 'text-right',
                          }
                        : null,
                      orderDetailColumns.includes('bv')
                        ? {
                            id: 'bv',
                            name: 'BV',
                            theadClass: 'text-right',
                          }
                        : null,
                      orderDetailColumns.includes('total_weight')
                        ? {
                            id: 'total_weight',
                            name: 'Total Berat',
                            theadClass: 'text-right',
                          }
                        : null,
                    ].filter((item) => !!item)
                  "
                >
                  <template #tbody="{ classes }">
                    <tr
                      v-for="(orderDetail, index) in orderDetails"
                      :key="index"
                      :class="classes.tr"
                    >
                      <td :class="[classes.td]">
                        <p class="font-bold text-gray-900">
                          {{ orderDetail.attributes.product_code }}
                          <span
                            v-if="
                              orderDetail.attributes.product_type === 'free'
                            "
                            >(Promo)</span
                          >
                        </p>
                      </td>
                      <td :class="[classes.td, 'text-right']">
                        {{ orderDetail.attributes.product_qty | toCurrency }}
                      </td>
                      <td
                        v-if="orderDetailColumns.includes('packed_qty')"
                        :class="[classes.td, 'text-right']"
                      >
                        {{ orderDetail.attributes.product_packed | toCurrency }}
                      </td>
                      <td
                        v-if="orderDetailColumns.includes('unpacked_qty')"
                        :class="[classes.td, 'text-right']"
                      >
                        {{
                          (orderDetail.attributes.product_qty -
                            orderDetail.attributes.product_packed)
                            | toCurrency
                        }}
                      </td>
                      <td
                        v-if="orderDetailColumns.includes('weight')"
                        :class="[classes.td, 'text-right']"
                      >
                        {{ orderDetail.attributes.product_weight | toCurrency }}
                      </td>
                      <td
                        v-if="orderDetailColumns.includes('price')"
                        :class="[classes.td, 'text-right']"
                      >
                        {{ orderDetail.attributes.product_price | toCurrency }}
                      </td>
                      <td :class="[classes.td, 'text-right']">
                        {{ orderDetail.attributes.total_price | toCurrency }}
                      </td>
                      <td
                        v-if="orderDetailColumns.includes('pv')"
                        :class="[classes.td, 'text-right']"
                      >
                        {{ orderDetail.attributes.point_value | toCurrency }}
                      </td>
                      <td
                        v-if="orderDetailColumns.includes('bv')"
                        :class="[classes.td, 'text-right']"
                      >
                        {{ orderDetail.attributes.bonus_value | toCurrency }}
                      </td>
                      <td
                        v-if="orderDetailColumns.includes('total_weight')"
                        :class="[classes.td, 'text-right']"
                      >
                        {{ orderDetail.attributes.total_weight | toCurrency }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:tfoot="{ classes }">
                    <tfoot>
                      <tr :class="classes.tr">
                        <td colspan="2"></td>
                        <td
                          v-if="orderDetailColumns.includes('packed_qty')"
                        ></td>
                        <td
                          v-if="orderDetailColumns.includes('unpacked_qty')"
                        ></td>
                        <td :class="[classes.td, 'text-right']">
                          Total Penjualan
                        </td>
                        <td :class="[classes.td, 'text-right']">
                          {{
                            order.data.attributes.grand_total_price | toCurrency
                          }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          withPayment && order.data.attributes.is_using_stock
                        "
                        :class="classes.tr"
                      >
                        <td colspan="2"></td>
                        <td
                          v-if="orderDetailColumns.includes('packed_qty')"
                        ></td>
                        <td
                          v-if="orderDetailColumns.includes('unpacked_qty')"
                        ></td>
                        <td :class="[classes.td, 'pt-0 text-right']">
                          Total Pembayaran
                        </td>
                        <td :class="[classes.td, 'pt-0 text-right']">
                          {{
                            order.data.attributes.payment_amount | toCurrency
                          }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          withPayment && order.data.attributes.is_using_stock
                        "
                        :class="classes.tr"
                      >
                        <td colspan="2"></td>
                        <td
                          v-if="orderDetailColumns.includes('packed_qty')"
                        ></td>
                        <td
                          v-if="orderDetailColumns.includes('unpacked_qty')"
                        ></td>
                        <td :class="[classes.td, 'pt-0 text-right']">
                          Total Kekurangan
                        </td>
                        <td :class="[classes.td, 'pt-0 text-right']">
                          {{
                            Math.max(
                              order.data.attributes.grand_total_price -
                                order.data.attributes.payment_amount,
                              0
                            ) | toCurrency
                          }}
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </datatable>
              </dd>
            </div>
            <div v-if="withBonus" class="col-span-full">
              <dt class="text-xs text-gray-700">Bonus</dt>
              <dd class="mt-1">
                <datatable
                  :scroll-x="false"
                  :columns="[
                    { id: 'code', name: 'Kode Barang' },
                    { id: 'name', name: 'Nama Barang' },
                    { id: 'qty', name: 'Jumlah' },
                  ]"
                >
                  <template #tbody="{ classes }">
                    <tr
                      v-for="(orderDetailBonus, index) in orderDetailBonuses"
                      :key="index"
                      :class="classes.tr"
                    >
                      <td :class="[classes.td]">
                        <p class="font-bold text-gray-900">
                          {{ orderDetailBonus.attributes.product_code }}
                        </p>
                      </td>
                      <td :class="[classes.td]">
                        {{ orderDetailBonus.attributes.product_code }}
                      </td>
                      <td :class="[classes.td]">
                        {{ orderDetailBonus.attributes.bonus_qty | toCurrency }}
                      </td>
                    </tr>
                  </template>
                </datatable>
              </dd>
            </div>
            <div
              v-if="withPayment && order.data.attributes.is_using_stock"
              class="col-span-full"
            >
              <dt class="text-xs text-gray-700">Pembayaran</dt>
              <dd class="mt-1">
                <payment-list
                  :summary="false"
                  :columns="[]"
                  :payments="{ data: payments }"
                  cardless
                />
              </dd>
            </div>
            <div
              v-if="withShipment && order.data.attributes.is_using_stock"
              class="col-span-full"
            >
              <dt class="text-xs text-gray-700">Pengiriman</dt>
              <dd class="mt-1">
                <datatable
                  :scroll-x="false"
                  :columns="[
                    { id: 'code', name: 'Kode Kemasan' },
                    { id: 'date', name: 'Tanggal' },
                    {
                      id: 'qty',
                      name: 'Total Barang',
                      theadClass: 'text-right',
                    },
                    { id: 'status', name: 'Status', theadClass: 'text-center' },
                  ]"
                >
                  <template #tbody="{ classes }">
                    <tr
                      v-for="(stockMovement, index) in stockMovements"
                      :key="index"
                      :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
                      @click="onDetailMovement(stockMovement)"
                    >
                      <td :class="[classes.td]">
                        <p class="font-bold text-gray-900">
                          {{ stockMovement.attributes.code }}
                        </p>
                      </td>
                      <td :class="[classes.td]">
                        {{ stockMovement.attributes.createdAt | formatDate }}
                      </td>
                      <td :class="[classes.td, 'text-right']">
                        {{
                          Object.keys(
                            stockMovement.attributes.product_summaries
                          ).length | toCurrency
                        }}
                      </td>
                      <td :class="[classes.td, 'text-center']">
                        <base-badge
                          :color="getShipmentStatusColor(stockMovement)"
                          >{{
                            getShipmentStatusText(stockMovement)
                          }}</base-badge
                        >
                      </td>
                    </tr>
                  </template>
                </datatable>
              </dd>
            </div>
          </dl>
        </div>
        <div class="flex justify-end gap-x-2">
          <slot name="actions" :stock-movements="stockMovements" />

          <base-button
            v-if="
              !order.data.attributes.deletedAt &&
              order.data.attributes.previous_order_id
            "
            color="white"
            @click="prevOrderModalVisible = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
            Faktur Awal
          </base-button>

          <template
            v-if="
              !readonly &&
              !order.data.attributes.is_valid_for_payment &&
              canActions
            "
          >
            <base-button color="white" @click="onDelete">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
              {{
                order.data.attributes.is_cancel
                  ? 'Batal Faktur Permanen'
                  : 'Hapus'
              }}
            </base-button>
            <base-button
              v-if="order.data.attributes.is_cancel"
              @click="onReissue"
              color="white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              Faktur Ulang
            </base-button>
            <base-button
              v-if="order.data.id && !order.data.attributes.is_cancel"
              :to="{
                name: editRouteName,
                params: {
                  id: order.data.id,
                },
              }"
              color="white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              Edit
            </base-button>
            <base-button
              v-if="!order.data.attributes.is_cancel"
              @click="onValidate"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                />
              </svg>
              Validasi
            </base-button>
          </template>
        </div>
      </base-card>
    </base-modal>

    <base-confirm
      v-if="deleteVisible"
      @close="deleteVisible = false"
      @confirmed="onDeleteConfirmed"
      size="max-w-xl"
      :icon="false"
      message="Ketik ulang kalimat berikut ini untuk mengkonfirmasi aksi pembatalan"
      :code="`Faktur dengan kode ${order.data.attributes.origin_code} akan saya hapus`"
      :center="false"
      input
      visible
    />

    <reissue-order-confirmation
      :order-id="reissueConfirmation.orderId"
      :visible="reissueConfirmation.visible"
      @close="reissueConfirmation.visible = false"
      @confirmed="onReissued"
    />

    <view-stock-movement-modal
      v-bind="stockMovementModalProps"
      readonly
      :visible="detailMovement.visible"
      :stock-movement-id="detailMovement.stockMovementId"
      @close="detailMovement.visible = false"
    />

    <view-order-modal
      label="Faktur Awal"
      :with-items="false"
      :with-shipment="false"
      :with-payment="false"
      v-if="order.data.attributes.previous_order_id"
      :order-id="order.data.attributes.previous_order_id"
      :visible="prevOrderModalVisible"
      @close="prevOrderModalVisible = false"
    />

    <teleport to="body">
      <loading v-if="loading" />
    </teleport>
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import Teleport from 'vue2-teleport';
import PaymentList from '@/components/payment/payment-list.vue';
import ViewStockMovementModal from '@/components/stock-movement/view-stock-movement-modal.vue';
import ReissueOrderConfirmation from './reissue-order-confirmation.vue';
import OrderDateLabel from './order-date-label.vue';
import { mapActions } from 'vuex';

export default {
  name: 'ViewOrderModal',
  mixins: [requestMixin],
  components: {
    BaseModal,
    Teleport,
    PaymentList,
    BaseConfirm,
    ViewStockMovementModal,
    ReissueOrderConfirmation,
    OrderDateLabel
  },
  props: {
    size: {
      type: String,
      default: 'max-w-5xl',
    },
    stockMovementModalProps: Object,
    visible: Boolean,
    orderId: String,
    type: String,
    editRouteName: {
      type: String,
      default: 'restock-pbs.edit',
    },
    label: {
      type: String,
      default: 'Restock',
    },
    withPayment: {
      type: Boolean,
      default: true,
    },
    withShipment: {
      type: Boolean,
      default: true,
    },
    withItems: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [
        'code',
        'warehouse',
        'office',
        'date',
        'user',
        'area',
        'shipment_type',
        'stock_type',
        'loan',
        'address',
      ],
    },
    orderDetailColumns: {
      type: Array,
      default: () => ['code', 'qty', 'price', 'total_price'],
    },
    readonly: Boolean,
    withBonus: Boolean,
  },
  emits: ['close', 'validated', 'deleted', 'reissued'],
  data() {
    return {
      deleteVisible: false,
      detailMovement: {
        visible: false,
        stockMovementId: null,
      },
      loading: false,
      order: {
        data: {
          attributes: {},
          relationships: {
            'destination-office': {
              data: null,
            },
            'order-details': {
              data: [],
            },
            'order-detail-bonuses': {
              data: [],
            },
            payments: {
              data: [],
            },
            'stock-movements': {
              data: [],
            },
          },
        },
        included: [],
      },
      prevOrderModalVisible: false,
      reissueConfirmation: {
        orderId: null,
        visible: false,
      },
      reload: true,
    };
  },
  computed: {
    canActions() {
      if (this.order.data.attributes.deletedAt) {
        return false;
      }

      if (!this.type) {
        return true;
      }

      if (
        this.order.data.attributes.order_type === 'purchase' &&
        this.type === 'purchase'
      ) {
        return true;
      }

      if (
        this.order.data.attributes.order_type === 'sale' &&
        this.type === 'sale'
      ) {
        return true;
      }

      return false;
    },
    destinationOffice() {
      if (!this.order.data.relationships['destination-office'].data) {
        return null;
      }

      return this.getSingleIncluded(
        this.order,
        this.order.data.relationships['destination-office'].data.id
      );
    },
    orderDetails() {
      if (!this.order.data.relationships['order-details'].data) {
        return null;
      }

      return this.getIncludedByType(this.order, 'order-details');
    },
    orderDetailBonuses() {
      if (!this.order.data.relationships['order-detail-bonuses'].data) {
        return null;
      }

      return this.getIncludedByType(this.order, 'order-detail-bonuses');
    },
    payments() {
      if (!this.order.data.relationships['payments'].data) {
        return null;
      }

      return this.getIncludedByType(this.order, 'payments');
    },
    stockMovements() {
      if (!this.order.data.relationships['stock-movements'].data) {
        return null;
      }

      return this.getIncludedByType(this.order, 'stock-movements');
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    getShipmentStatusColor(stockMovement) {
      if (!stockMovement.attributes.is_valid_for_shipment) {
        return 'yellow';
      }

      if (!stockMovement.attributes.is_shipped) {
        return 'red';
      }

      if (!stockMovement.attributes.is_received) {
        return 'yellow';
      }

      return 'green';
    },
    getShipmentStatusText(stockMovement) {
      if (!stockMovement.attributes.is_valid_for_shipment) {
        return 'Belum Dikemas';
      }

      if (!stockMovement.attributes.is_shipped) {
        return 'Belum Dikirim';
      }

      if (!stockMovement.attributes.is_received) {
        return 'Belum Diterima';
      }

      return 'Diterima';
    },
    onClose() {
      this.reload = true;

      this.$emit('close');
    },
    onDelete() {
      this.reload = false;
      this.deleteVisible = true;
    },
    async onDeleteConfirmed() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/orders/${this.order.data.id}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'orders',
              id: this.order.data.id,
              attributes: {
                deletedAt: new Date(),
              },
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.deleteVisible = false;
        this.reload = true;

        this.$emit('deleted');
      }

      this.loading = false;
    },
    async onOpened() {
      if (this.reload) {
        this.loading = true;

        const [res, err] = await this.request(
          `/api/v1/orders/${this.orderId}`,
          {
            params: {
              include:
                'destination-office,order-details,order-detail-bonuses,payments,stock-movements',
              'fields[orders]':
                'deletedAt,previous_order_id,origin_code,destination_code,is_valid_for_payment,order_status,is_cancel,origin_office_code,origin_warehouse_code,destination_office_code,destination_warehouse_code,createdAt,orderedAt,order_type,created_by,area_name,area_code,order_shipment,is_using_stock,is_loan,shipment_address,grand_total_price,payment_amount,destination-office,order-details,payments,stock-movements,latest_update_by,updatedAt,order-detail-bonuses',
              'fields[order-details]':
                'product_code,product_qty,product_packed,product_received,product_weight,product_price,total_price,product_type,point_value,bonus_value,total_weight',
              'fields[payments]':
                'code,destination_office_code,origin_warehouse_code,createdAt,total_amount,state,is_verified',
              'fields[stock-movements]':
                'code,createdAt,product_summaries,is_shipped,is_received,is_valid_for_shipment',
              'fields[order-detail-bonuses]':
                'product_code,product_name,bonus_qty',
            },
          }
        );

        if (!err) {
          this.order = res;
        }

        this.loading = false;
      }
    },
    async onValidate() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/orders/${this.order.data.id}/-actions/validateOrder`,
        {
          method: 'patch',
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.reload = true;

        this.$emit('validated');
      }

      this.loading = false;
    },
    async onDetailMovement(movement) {
      this.detailMovement.visible = true;
      this.detailMovement.stockMovementId = movement.id;

      this.reload = false;
    },
    onReissue() {
      this.reissueConfirmation.orderId = this.order.data.id;
      this.reissueConfirmation.visible = true;
    },
    onReissued() {
      this.reissueConfirmation.visible = false;

      this.onClose();

      this.$emit('reissued');
    },
  },
};
</script>
